import packageInfo from '../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  name: 'demo',
  production: false,
  hmr: false,
  baseApiUrl: 'https://demo-api.myplane.io/api/',
  baseUrl: 'https://demo.myplane.io',
  googleWebFontsApiKey: 'AIzaSyBBTgxLVKkkZy_Xj2h6l7HuBlgB-kAsFJQ',
  mapsApiKey: 'AIzaSyAC6ZMBTOyktKQ9WQc_y_vV0b2tmlt1Hlo',
  cloudBucketName: 'myplane-staging',
};
