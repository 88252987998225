<div fxLayout="row" fxLayoutAlign="space-between center" class="title-bar-dialog">
  <div fxLayout="column">
    <h2 mat-dialog-title>{{ 'forms.filePreview' | translate }}</h2>
  </div>
  <div fxLayout="column">
    <button class="close" mat-icon-button mat-dialog-close aria-label="Close box">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content *ngIf="objectId">
  <app-file-viewer
    [showButtons]="false"
    [authId]="objectId"
    [fileId]="src"
    [typePrivateFile]="typePrivateFile"
    [dialog]="true"
    style="width: 100%; min-height: 100%"></app-file-viewer>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="space-between center">
  <button
    data-test="close-download-file"
    mat-raised-button
    mat-dialog-close
    (click)="matDialogRef.close()">
    {{ 'forms.closeBtn' | translate }}
  </button>
  <button
    data-test="download-file"
    class="mp-yellow"
    mat-raised-button
    [color]="'accent'"
    (click)="download()"
    class="mp-yellow">
    {{ 'forms.downloadFileBtn' | translate }}
  </button>
</mat-dialog-actions>
